import React, { useEffect, useState } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

const SetUpCustomerPage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [companylist,setCompanylist] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const navigate = useNavigate();

    const handleEditClick = (item) => {
        setSelectedItem(item);
        getcompanyvehicle(item.company_id);
        setIsEditOpen(true);
    };
    const getCompanyNameFromId = (companyId) => {
        
        const company = companylist.find(company => company.id === companyId);
        return company.name
    };

    const getCompanyAddressFromId = (companyId) => {
        
        const company = companylist.find(company => company.id === companyId);
        
        return company.address;
    };

    const handleEditInputChange = (property, value) => {
        if(property=='company_id'){
            setSelectedItem({
                ...selectedItem,
                [property]: value,
                ['company_name']: getCompanyNameFromId(value),
                ['company_address']: getCompanyAddressFromId(value)
            });
        }else{
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    }
    };

    const handleAddInputChange = (property, value) => {
        if(property=='company_id'){
            if(value==""){
                setSelectedItem({
                    ...selectedItem,
                    [property]: '',
                    ['company_name']: '',
                    ['company_address']: '',
                });
            }else{
            setSelectedItem({
                ...selectedItem,
                [property]: value,
                ['company_name']: getCompanyNameFromId(value),
                ['company_address']: getCompanyAddressFromId(value)
            });
        }
        }else{
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    }
    };

    const clearSelectedItem = () => {
        setSelectedItem(null);
        setSelectedItem('company_id','');
    };

    const getcompany = () => {
        const formData = new FormData();
        const url = "https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-company";
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setCompanylist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const getcompanyvehicle = (id) => {
        const formData = new FormData();
        const url = "https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-customer-vehicle";
        formData.append('company_id', id);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setVehicleData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    
    const AddNew = () =>{
        clearSelectedItem();
        setIsAddNewOpen(true);
    };

    const EditSave = () =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=edit-customer";
        formData.append('id', selectedItem.id);
        formData.append('name', selectedItem.name);
        formData.append('username', selectedItem.username);
        formData.append('password', selectedItem.password);
        formData.append('company_id', selectedItem.company_id);
        formData.append('phone', selectedItem.phone);
        formData.append('email', selectedItem.email);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Changes completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsEditOpen(false);
                            getcustomerlist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
        
    }
    else{
        toast.error("Changes canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };
    
    const addnewcustomer = () =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-customer";
        formData.append('name', selectedItem.name);
        formData.append('username', selectedItem.username);
        formData.append('password', selectedItem.password);
        formData.append('company_id', selectedItem.company_id);
        formData.append('phone', selectedItem.phone);
        formData.append('email', selectedItem.email);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Customer Create Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getcustomerlist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    }
    else{
        toast.error("Customer Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =(page)=>{
            PageNumber(page);
            getcustomerlist('',page)
        }

    const searchfunction =(value)=>{
        setSearchData(value);
        getcustomerlist(value,1);
    }
    const getcustomerlist = (searchvalue,page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-customer";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));

        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                return postData.map((item, index) => (
                    <tr key={index}>
                        <td>{(pageno-1)*20+index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.company_name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
            </td>
        </tr>
                )
            )
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    useEffect(() => {
            getcustomerlist('',pageno);
            getcompany();
    }, []);
    

    return (
            <div className='main-content'>
                <TopPageHeader title='Customer' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Customer</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Customer'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={() => addnewcustomer()}>Save</button>}
                            >
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Name</label>
                                        <input className='form-control__input' type="text" placeholder='Name' onChange={(e) => handleAddInputChange('name', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>User Name</label>
                                        <input className='form-control__input' type="text" placeholder='User Name' onChange={(e) => handleAddInputChange('username', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>User Password</label>
                                        <input className='form-control__input' type="text" placeholder='User Password' onChange={(e) => handleAddInputChange('password', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id || "" } onChange={(e) => handleAddInputChange('company_id', e.target.value)}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Contact</label>
                                        <input className='form-control__input' type="text" placeholder='Contact' onChange={(e) => handleAddInputChange('phone', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Email</label>
                                        <input className='form-control__input' type="text" placeholder='Email' onChange={(e) => handleAddInputChange('email', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Address</label>
                                        <input className='form-control__input' type="text" placeholder='Address' value={selectedItem.company_address}  disabled={true} style={{backgroundColor:'#C0C0C0'}} />
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>

                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Company Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(pageno-1)*20+index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.company_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>
                                                <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Edit Customer Details'
                                setIsOpen={setIsEditOpen}
                                actionBtn={<button className='form-action-btn' onClick={() => EditSave()}>Save </button>}
                            >
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Name</label>
                                        <input className='form-control__input' type="text" placeholder='Name' value={selectedItem.name} onChange={(e) => handleEditInputChange('name', e.target.value)}/>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>User ID</label>
                                        <input className='form-control__input' type="text" placeholder='User ID' value={selectedItem.id} disabled={true}  style={{backgroundColor:'#C0C0C0'}}/>
                                    </div>
                                    <div className='form-control'>
                                        <label className='form-control__label'>User Name</label>
                                        <input className='form-control__input' type="text" placeholder='User Name' value={selectedItem.username} disabled={true} style={{backgroundColor:'#C0C0C0'}}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>User Password</label>
                                        <input className='form-control__input' type="text" placeholder='User Password' value={selectedItem.password} onChange={(e) => handleEditInputChange('password', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id} onChange={(e) => handleEditInputChange('company_id', e.target.value)}>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Contact</label>
                                        <input className='form-control__input' type="text" placeholder='Contact' value={selectedItem.phone} onChange={(e) => handleEditInputChange('phone', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Email</label>
                                        <input className='form-control__input' type="text" placeholder='Email' value={selectedItem.email} onChange={(e) => handleEditInputChange('email', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Address</label>
                                        <input className='form-control__input' type="text" placeholder='Address' value={selectedItem.company_address} disabled={true} style={{backgroundColor:'#C0C0C0'}}/>
                                    </div>
                                </div>

                                <h4 className='data-table-title mt-25'>Registered Vehicle</h4>

                                <div className='data-table-2'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Vehicle No.</th>
                                                <th>Equipment Type</th>
                                                <th>Brand</th>
                                                <th>Model</th>
                                                <th>Reg No.</th>
                                                <th>Last Service</th>
                                                <th>Next Service</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {vehicleData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.vehicle_no}</td>
                                                <td>{item.equipment_type}</td>
                                                <td>{item.brand}</td>
                                                <td>{item.model}</td>
                                                <td>{item.registration_no}</td>
                                                <td>{item.last_service}</td>
                                                <td>{item.next_service}</td>
                                            </tr>
    ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal>
                        }
                    </div>
                </section>
            </div>
    )
}

export default SetUpCustomerPage;