import React, { useState } from 'react';

function LeftNavMenu({ title, icon, children }) {
    const [isShowSubNav, setIsShowNav] = useState(false);
    return (
        <>
            <button className='left-nav__nav--btn' onClick={() => { setIsShowNav(!isShowSubNav) }}>
                {icon}
                <p className='nav-btn-text'>{title}</p>
                <img className='arrow-icon' src='./../images/right-arrow-icon.png' alt='arrow-icon' style={{ transform: isShowSubNav ? 'rotate(90deg)' : '' }} />
            </button>
            {
                isShowSubNav &&
                <nav className='left-nav__nav--sub-nav'>
                    {children}
                </nav>
            }
        </>
    )
}

export default LeftNavMenu;