import React, { useState } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { useNavigate } from 'react-router-dom'

function DashboardPage() {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <div className='main-content'>
            <TopPageHeader title='Dashboard' />

            <section className='content-area dashboard'>
                <header className='content-area__header'>
                    <div>
                        <button className='content-area__header--btn w-150' onClick={() => { setIsFilterOpen(true) }}>+ Filter</button>

                        {
                            isFilterOpen &&
                            <Modal
                                title='Apply filter'
                                setIsOpen={setIsFilterOpen}
                                modelStyle='light-center'
                                style={{ width: '47rem', height: '40rem' }}
                            >
                                <div className='filter-row'>
                                    <label className='filter-row__btn'>
                                        Sales Trend<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Sales<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Total Man Hours<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Utilization of man hours<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Aging<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Parts revenue &  Labour revenue<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Jobs Pending<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Average per job<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Part Waiting Time<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Man Power Efficiency<input type="checkbox" />
                                    </label>

                                    <label className='filter-row__btn'>
                                        Top Customer<input type="checkbox" />
                                    </label>
                                </div>
                            </Modal>
                        }
                    </div>

                    <div className='d-flex'>
                        <div class="form-control">
                            <label class="form-control__label">From:</label>
                            <input class="form-control__input" placeholder="From" type="date" />
                        </div>

                        <div class="form-control">
                            <label class="form-control__label">To:</label>
                            <input class="form-control__input" placeholder="To" type="date" />
                        </div>
                    </div>
                </header>

                <div className='dashboard__charts scroll-sm'>
                    <div className='dashboard__charts__col-2by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Sales Trend</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Sales</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Total Man Hours</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Utiization of man hours</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Aging</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Parts revenue & Labour revenue</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Total Job</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by3'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Jobs pending</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by2'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Average per Job</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by2'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Part waiting time</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by2'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Man Power Efficiency</h3>
                        </div>
                    </div>

                    <div className='dashboard__charts__col-1by2'>
                        <div className='chart-box'>
                            <h3 className='chart-box__title'>Top Customer</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DashboardPage;