import React, { useEffect, useState } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SetUpForemanPage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const navigate = useNavigate();

    const handleEditClick = (item) => {
        setSelectedItem(prevItem => {
            const updatedItem = { ...prevItem, ...item };
            updatedItem.url = item.picture ? `https://adminpanel.mewahautoworks.com.my/api/${item.picture}` : './../images/pic-holder-02.jpg';
            return updatedItem;
        });
        setIsEditOpen(true);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const url = URL.createObjectURL(selectedFile);
    
        setSelectedItem(prevState => ({
            ...prevState,
            picture: selectedFile,
            url: url,
        }));
    };

    const getStaffNameFromId = (staffId) => {
        
        const user = userData.find(user => user.id === staffId);
        return user.name
    };

    const handleEditInputChange = (property, value) => {
        if(property=='id'){
            setSelectedItem({
                ...selectedItem,
                ['name']: getStaffNameFromId(value),
            });
        }
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =(page)=>{
            PageNumber(page);
            getforemanlist('',page)
        }

    const AddNew = () =>{
        clearSelectedItem();
        getuserlist('')
        setIsAddNewOpen(true);
    };
    
    const clearSelectedItem = () => {
        setSelectedItem(null);
        setSelectedItem({
            ...selectedItem,
            ['id']: '',
            ['url']:'./../images/pic-holder-02.jpg',
        });
    };
    
    const searchfunction =(value)=>{
        setSearchData(value);
        getforemanlist(value,1);
    }

    const getforemanlist = (searchvalue,page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-foreman";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));

        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                return postData.map((item, index) => (
                    <tr key={index}>
                        <td>{(pageno-1)*20+index + 1}</td>
                        <td>{item.id}</td>
                        <td>{item.level}</td>
                        <td>{item.hourly_rate}</td>
                        <td>{item.salary}</td>
                        <td>{item.skill1}</td>
                        <td>{item.skill2}</td>
                        <td>{item.skill3}</td>
                        <td>{item.skill4}</td>
                        <td>{item.skill5}</td>
                        <td>{item.total}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
            </td>
        </tr>
                )
            )
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    const editforeman = () => {
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=edit-staff";
        formData.append('id', selectedItem.id);
        formData.append('name', selectedItem.name);
        formData.append('username', selectedItem.username);
        formData.append('password', selectedItem.password);
        formData.append('ic_no', selectedItem.ic_no);
        formData.append('department_id', selectedItem.department_id);
        formData.append('date_join', selectedItem.date_join);
        formData.append('technician', true);
        formData.append('level', selectedItem.level);
        formData.append('hourly_rate', selectedItem.hourly_rate);
        formData.append('salary', selectedItem.salary);
        formData.append('skill1', selectedItem.skill1);
        formData.append('skill2', selectedItem.skill2);
        formData.append('skill3', selectedItem.skill3);
        formData.append('skill4', selectedItem.skill4);
        formData.append('skill5', selectedItem.skill5);
        formData.append('picture', selectedItem.picture);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                console.log(data);
                toast.success("Customer Create Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getforemanlist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    }
    else{
        toast.error("Customer Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    const getuserlist = (searchvalue) => {
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-staff1";
        formData.append('searchword', searchvalue);

        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setUserData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    useEffect(() => {
        getforemanlist('',pageno);
        
}, []);
    return (
            <div className='main-content'>
                <TopPageHeader title='Foreman' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Foreman</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Foreman'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={() => editforeman()}>Save</button>}
                                style={{ width: '94rem', height: '66rem' }}
                            >
                                <div className='foreman-detail'>
                                    <div className='foreman-detail__pic'>
                                        <img className='foreman-pic' src={selectedItem.url} alt='foreman pic' />
                                        <label className='file-upload'>
                                            Upload
                                            <input type="file" onChange={handleFileChange}/>
                                        </label>
                                    </div>

                                    <div className='foreman-detail__content'>
                                        <p className='form-control__label'>Details</p>
                                        <div className='grid-2-form'>
                                            <div className='form-control'>
                                                <label className='form-control__label'>Staff ID</label>
                                                <input className='form-control__input' placeholder='Staff ID' value={selectedItem.id} disabled={true} style={{backgroundColor:'#C0C0C0'}} />
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Level</label>
                                                <input className='form-control__input' type="text" placeholder='Level' value={selectedItem.level} onChange={(e) => handleEditInputChange('level', e.target.value)} />
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Name</label>
                                                <select className='form-control__input' placeholder='Name' value={selectedItem.id} onChange={(e) => handleEditInputChange('id', e.target.value)}>
                                                <option value=''>Please Select A Staff</option>
                                                {userData.map((user) => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                        </select>
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Salary</label>
                                                <input className='form-control__input' type="text" placeholder='Salary' value={selectedItem.salary} onChange={(e) => handleEditInputChange('salary', e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className='grid-2-form mt-25'>
                                            <div>
                                                <p className='form-control__label'>Skill</p>
                                            </div>

                                            <div>
                                                <p className='form-control__label'>Score</p>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Safety Compliance</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill1} onChange={(e) => handleEditInputChange('skill1', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Team Leadership</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill2} onChange={(e) => handleEditInputChange('skill2', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Engine Skills</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill3} onChange={(e) => handleEditInputChange('skill3', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Communication Skills</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill4} onChange={(e) => handleEditInputChange('skill4', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Problem-Solving</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill5} onChange={(e) => handleEditInputChange('skill5', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>

                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Staff ID</th>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Rate/Hrs</th>
                                        <th>Salary</th>
                                        <th>Skill 1</th>
                                        <th>Skill 2</th>
                                        <th>Skill 3</th>
                                        <th>Skill 4</th>
                                        <th>Skill 5</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.level}</td>
                        <td>{item.hourly_rate}</td>
                        <td>{item.salary}</td>
                        <td>{item.skill1}</td>
                        <td>{item.skill2}</td>
                        <td>{item.skill3}</td>
                        <td>{item.skill4}</td>
                        <td>{item.skill5}</td>
                        <td>{item.total}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                        </td>
                    </tr>
                                
                            ))
                    }
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title='Edit Foreman'
                                setIsOpen={setIsEditOpen}
                                actionBtn={<button className='form-action-btn' onClick={() =>editforeman()}>Save</button>}
                                style={{ width: '94rem', height: '66rem' }}
                            >
                                <div className='foreman-detail'>
                                    <div className='foreman-detail__pic'>
                                        <img className='foreman-pic' src={selectedItem.url} alt='foreman pic' />
                                        <label className='file-upload'>
                                            Upload
                                            <input type="file" onChange={handleFileChange}/>
                                        </label>
                                    </div>

                                    <div className='foreman-detail__content'>
                                        <p className='form-control__label'>Details</p>
                                        <div className='grid-2-form'>
                                            <div className='form-control'>
                                                <label className='form-control__label'>Staff ID</label>
                                                <input className='form-control__input' type="text" placeholder='Staff ID' value={selectedItem.id} disabled={true} style={{backgroundColor:'#C0C0C0'}} />
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Level</label>
                                                <input className='form-control__input' type="text" placeholder='Level' value={selectedItem.level} onChange={(e) => handleEditInputChange('level', e.target.value)}/>
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Name</label>
                                                <input className='form-control__input' type="text" placeholder='Name' value={selectedItem.name} disabled={true} style={{backgroundColor:'#C0C0C0'}}/>
                                            </div>

                                            <div className='form-control'>
                                                <label className='form-control__label'>Salary</label>
                                                <input className='form-control__input' type="text" placeholder='Salary' value={selectedItem.salary} onChange={(e) => handleEditInputChange('salary', e.target.value)} />
                                            </div>
                                        </div>

                                        <div className='grid-2-form mt-25'>
                                            <div>
                                                <p className='form-control__label'>Skill</p>
                                            </div>

                                            <div>
                                                <p className='form-control__label'>Score</p>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Safety Compliance</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill1} onChange={(e) => handleEditInputChange('skill1', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Team Leadership</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill2} onChange={(e) => handleEditInputChange('skill2', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Engine Skills</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill3} onChange={(e) => handleEditInputChange('skill3', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Communication Skills</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill4} onChange={(e) => handleEditInputChange('skill4', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>

                                                <div className='score-row'>
                                                    <p className='score-row__label'>Problem-Solving</p>
                                                    <input className='score-row__input' type="text" placeholder='5' value={selectedItem.skill5} onChange={(e) => handleEditInputChange('skill5', e.target.value)}/>
                                                    <p className='score-row__text'>/20</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                </section>
            </div>
    )
}

export default SetUpForemanPage;