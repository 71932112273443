import React from 'react';
import LeftNav from '../components/LeftNav';
import { Outlet } from 'react-router-dom';

function CorePage() {
  return (
    <div className='main-wrapper'>
      <LeftNav />
      <Outlet />
    </div>
  )
}

export default CorePage;