import React from 'react';
import TopPageHeader from '../components/TopPageHeader';
import ComingSoon from '../components/ComingSoon';

function TechAssistantPage() {
    return (
        <div className='main-content'>
            <TopPageHeader title='Tech Assistant' />

            <section className='content-area'>
                <ComingSoon />
            </section>
        </div>
    )
}

export default TechAssistantPage;