import React from 'react';
import { NavLink } from 'react-router-dom';

function ComingSoon() {
    return (
        <div className='coming-soon'>
            <h2 className='coming-soon__title'>Coming Soon</h2>
            <NavLink to='/core/dashboard' className='coming-soon__outline-btn'>Back to dashboard</NavLink>
        </div>
    )
}

export default ComingSoon;