import React from 'react';

const Modal = ({ title, children, setIsOpen, actionBtn, style, modelStyle }) => {
    return (
        <>
            <div className='modal-dark-bg'>
                <div className='modal-centered'>
                    <div className={`modal ${modelStyle ? modelStyle : ''}`} style={style}>
                        <button className='modal__close-btn' onClick={() => setIsOpen(false)}>
                            <img className='icon' src='./../images/close-icon.png' alt='close icon' />
                        </button>
                        <h3 className='modal__title'>{title}</h3>
                        <div className='modal__content'>{children}</div>
                        <div className='modal__footer'>{actionBtn}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;