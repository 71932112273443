import React from 'react';

function TopPageHeader({ title }) {

    return (
        <header className='top-page-header'>
            <h1 className='top-page-header__title'>{title}</h1>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='top-page-header__icon-btn'>
                    <img src='./../images/bell-icon.png' alt='bell icon' />
                </button>
                <div className='top-page-header__user'>
                    <div>
                        <p className='top-page-header__user--text'>User Name</p>
                        <p className='top-page-header__user--text'>{sessionStorage.getItem('loginusername')}</p>
                    </div>
                    <img className='top-page-header__user--pic' src='./../images/pic_holder.png' alt='user pic' />
                </div>
            </div>
        </header>
    )
}

export default TopPageHeader;