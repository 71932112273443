import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'


function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setpassword] = useState('');
    const navigate = useNavigate();
    
    
    const login = () => {
        
        const formData = new FormData();
        const url="https://adminpanel.mewahautoworks.com.my/api/admin_login.php";

        formData.append('username', username);
        formData.append('password', password);
        return fetch(url, {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('API Failed');
            }
            return response.json();
        })
        .then((data) => {
            const { token, user_info } = data;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('loginid', user_info.id);
            sessionStorage.setItem('loginname', user_info.name);
            sessionStorage.setItem('loginusername', user_info.username);
            sessionStorage.setItem('logindid', user_info.department_id);
            sessionStorage.setItem('logindname', user_info.department_name);
            navigate('/core/dashboard');
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          login();
        }
      };

    return (
        <div className='main-wrapper'>
            <div className='login-form'>
                <img className='login-form__logo' src='./../images/logo.png' alt='logo' />
                <h1 className='login-form__title'>Login</h1>

                <input className='login-form__input' type="text" placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                <input className='login-form__input' type="password" placeholder='Password' onKeyPress={handleKeyPress} value={password} onChange={(e) => setpassword(e.target.value)}/>

                <button className='login-form__forgot-btn'>Forgot Password</button> 
                <button className='login-form__login-btn' onClick={()=>login()}>Login</button>
            </div>

            <div className='login-bg'></div>
        </div>
    )
}

export default LoginPage;