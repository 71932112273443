import React, { useEffect, useState } from 'react';
import LeftNav from '../components/LeftNav';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import moment from"moment";
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SetUpVehiclePage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [postData, setPostData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [companylist,setCompanylist] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const navigate = useNavigate();

    const handleEditClick = (item) => {
        setSelectedItem(item);
        getcompany();
        setIsEditOpen(true);
    };
    const searchfunction =(value)=>{
        setSearchData(value);
        getvehiclelist(value,1);
    }
    const AddNew = () =>{
        clearSelectedItem();
        getcompany();
        setIsAddNewOpen(true);
    };

    const clearSelectedItem = () => {
        setSelectedItem(null);
        setSelectedItem(['company_id'],'');
    };

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =(page)=>{
            PageNumber(page);
            getvehiclelist('',page)
        }

    const getCompanyNameFromId = (companyId) => {
        
        const company = companylist.find(company => company.id === companyId);
        return company.name;
    };

    const getcompany = () => {
        const formData = new FormData();
        const url = "https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-company";
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setCompanylist(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

        const handleInputChange = (property, value) => {
        if(property=='company_id'){
            if(value==''){
                setSelectedItem({
                    ...selectedItem,
                    [property]: '',
                    ['company_name']: '',
                });
            }else{
            setSelectedItem({
                ...selectedItem,
                [property]: value,
                ['company_name']: getCompanyNameFromId(value),
            });
        }
        }else{
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    }
    };

    const EditSave = () =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=edit-customer-vehicle";
        formData.append('id', selectedItem.id);
        formData.append('vehicle_no', selectedItem.vehicle_no);
        formData.append('registration_no', selectedItem.registration_no);
        formData.append('company_id', selectedItem.company_id);
        formData.append('brand', selectedItem.brand);
        formData.append('equipment_type', selectedItem.equipment_type);
        formData.append('model', selectedItem.model);
        formData.append('last_service', moment(selectedItem.last_service).format('YYYY-MM-DD'));
        formData.append('next_service', moment(selectedItem.next_service).format('YYYY-MM-DD'));
        formData.append('road_tax_expiry', moment(selectedItem.road_tax_expiry).format('YYYY-MM-DD'));
        formData.append('chassis_no', selectedItem.chassis_no);
        formData.append('engine_no', selectedItem.engine_no);
        formData.append('insurance_expiry', moment(selectedItem.insurance_expiry).format('YYYY-MM-DD'));
        formData.append('next_puspakom', moment(selectedItem.next_puspakom).format('YYYY-MM-DD'));
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Changes completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsEditOpen(false);
                            getvehiclelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
        
    }
    else{
        toast.error("Changes canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    const addnewvehicle = () =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-customer-vehicle";
        formData.append('vehicle_no', selectedItem.vehicle_no);
        formData.append('registration_no', selectedItem.registration_no);
        formData.append('company_id', selectedItem.company_id);
        formData.append('brand', selectedItem.brand);
        formData.append('equipment_type', selectedItem.equipment_type);
        formData.append('model', selectedItem.model);
        formData.append('last_service', moment(selectedItem.last_service).format('YYYY-MM-DD'));
        formData.append('next_service', moment(selectedItem.next_service).format('YYYY-MM-DD'));
        formData.append('last_mileage', selectedItem.last_mileage);
        formData.append('next_mileage', selectedItem.next_mileage);
        formData.append('road_tax_expiry', moment(selectedItem.road_tax_expiry).format('YYYY-MM-DD'));
        formData.append('chassis_no', selectedItem.chassis_no);
        formData.append('engine_no', selectedItem.engine_no);
        formData.append('insurance_expiry', moment(selectedItem.insurance_expiry).format('YYYY-MM-DD'));
        formData.append('next_puspakom', moment(selectedItem.next_puspakom).format('YYYY-MM-DD'));
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Vehicle Add Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getvehiclelist('',1);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    }
    else{
        toast.error("Customer Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    const getvehiclelist = (searchvalue,page) => {
        let offset=(page-1)*20;
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin_alfred.php?mode=get-customer-vehicle";
        formData.append('searchword', searchvalue);
        formData.append('offset',String(offset));
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                setPostData(data);
                return postData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.vehicle_no}</td>
                        <td>{item.equipment_type}</td>
                        <td>{item.company_name}</td>
                        <td>{item.registration_no}</td>
                        <td>{item.brand}</td>
                        <td>{item.last_service}</td>
                        <td>
                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
            </td>
        </tr>
                )
            )
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };

    useEffect(() => {
        setPageNumber(1);
    }, []);

    return (
            <div className='main-content'>
                <TopPageHeader title='Vehicle' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Vechicle</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Vehicle'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={()=>addnewvehicle()}>Save</button>}
                            >
                                <div className='grid-3-form'>
                                    
                                        <div className='form-control'>
                                        <label className='form-control__label'>Reg No.</label>
                                        <input className='form-control__input' type="text" placeholder=' Reg No.' value={selectedItem.registration_no} onChange={(e) => handleInputChange('registration_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Road Tax Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Road Tax Expiry' selected={selectedItem.road_tax_expiry} onChange={(date) => handleInputChange('road_tax_expiry', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id || "" } onChange={(e) => handleInputChange('company_id', e.target.value)}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No.</label>
                                        <input className='form-control__input' type="text" placeholder='Vehicle No.' value={selectedItem.vehicle_no} onChange={(e) => handleInputChange('vehicle_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Puspakom</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Puspakom' selected={selectedItem.next_puspakom} onChange={(date) => handleInputChange('next_puspakom', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Equipment Type</label>
                                        <input className='form-control__input' type="text" placeholder='Equipment Type' value={selectedItem.equipment_type} onChange={(e) => handleInputChange('equipment_type', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Chasis No.</label>
                                        <input className='form-control__input' type="text" placeholder='Chasis No.' value={selectedItem.chassis_no} onChange={(e) => handleInputChange('chassis_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.last_service} onChange={(date) => handleInputChange('last_service', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Mileage</label>
                                        <input className='form-control__input' placeholder='Last Mileage' selected={selectedItem.last_mileage} onChange={(e) => handleInputChange('last_mileage', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Engine No.</label>
                                        <input className='form-control__input' type="text" placeholder='Engine No.' value={selectedItem.engine_no} onChange={(e) => handleInputChange('engine_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.next_service} onChange={(date) => handleInputChange('next_service', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Mileage</label>
                                        <input className='form-control__input' placeholder='Next Mileage' selected={selectedItem.next_mileage} onChange={(e) => handleInputChange('next_mileage', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Brand</label>
                                        <input className='form-control__input' type="text" placeholder='Brand' value={selectedItem.brand} onChange={(e) => handleInputChange('brand', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Model</label>
                                        <input className='form-control__input' type="text" placeholder='Model' value={selectedItem.model} onChange={(e) => handleInputChange('model', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Insurance Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Insurance Expiry' selected={selectedItem.insurance_expiry} onChange={(date) => handleInputChange('insurance_expiry', date)} dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>

                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Vehicle No.</th>
                                        <th>Equipment Type</th>
                                        <th>Customer Name</th>
                                        <th>Vehicle Registration No.</th>
                                        <th>Model</th>
                                        <th>Last Service</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.vehicle_no}</td>
                                        <td>{item.equipment_type}</td>
                                        <td>{item.company_name}</td>
                                        <td>{item.registration_no}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.last_service}</td>
                                        <td>
                                        <button className='view-btn' onClick={() => handleEditClick(item)}>View details</button>
                                    </td>
                                    </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {
                            isEditOpen &&
                            <Modal
                                title=' Vehicle Details'
                                setIsOpen={setIsEditOpen}
                                actionBtn={<button className='form-action-btn' onClick={()=>EditSave()}>Save</button>}
                            >
                                <div className='grid-3-form'>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Reg No.</label>
                                        <input className='form-control__input' type="text" placeholder=' Reg No.' value={selectedItem.registration_no} onChange={(e) => handleInputChange('registration_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Road Tax Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Road Tax Expiry' selected={selectedItem.road_tax_expiry} onChange={(date) => handleInputChange('road_tax_expiry', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <select className='form-control__input' placeholder='Company Name' value={selectedItem.company_id || "" } onChange={(e) => handleInputChange('company_id', e.target.value)}>
                                        <option value="" >Select a company</option>
                                        {companylist.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Vehicle No.</label>
                                        <input className='form-control__input' type="text" placeholder='Vehicle No.' value={selectedItem.vehicle_no} onChange={(e) => handleInputChange('vehicle_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Puspakom</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Puspakom' selected={selectedItem.next_puspakom} onChange={(date) => handleInputChange('next_puspakom', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Equipment Type</label>
                                        <input className='form-control__input' type="text" placeholder='Equipment Type' value={selectedItem.equipment_type} onChange={(e) => handleInputChange('equipment_type', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Chasis No.</label>
                                        <input className='form-control__input' type="text" placeholder='Chasis No.' value={selectedItem.chassis_no} onChange={(e) => handleInputChange('chassis_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Last Service' selected={selectedItem.last_service} onChange={(date) => handleInputChange('last_service', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Last Mileage</label>
                                        <input className='form-control__input' placeholder='Last Mileage' selected={selectedItem.last_mileage} onChange={(e) => handleInputChange('last_mileage', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Engine No.</label>
                                        <input className='form-control__input' type="text" placeholder='Engine No.' value={selectedItem.engine_no} onChange={(e) => handleInputChange('engine_no', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Service</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Next Service' selected={selectedItem.next_service} onChange={(date) => handleInputChange('next_service', date)} dateFormat="dd/MM/yyyy"/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Next Mileage</label>
                                        <input className='form-control__input' placeholder='Next Mileage' selected={selectedItem.next_mileage} onChange={(e) => handleInputChange('next_mileage', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Brand</label>
                                        <input className='form-control__input' type="text" placeholder='Brand' value={selectedItem.brand} onChange={(e) => handleInputChange('brand', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Model</label>
                                        <input className='form-control__input' type="text" placeholder='Model' value={selectedItem.model} onChange={(e) => handleInputChange('model', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Insurance Expiry</label>
                                        <DatePicker className='form-control__input datepicker' placeholder='Insurance Expiry' selected={selectedItem.insurance_expiry} onChange={(date) => handleInputChange('insurance_expiry', date)} dateFormat="dd/MM/yyyy" />
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                </section>
            </div>
    )
}

export default SetUpVehiclePage;