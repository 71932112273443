import React from 'react';
import PropTypes from 'prop-types';

export const NumberInput = ({ value, onChange, style }) => {
        const handleInputChange = (e) => {
            const newValue = parseInt(e.target.value, 10);
            if (!isNaN(newValue)) {
                onChange(newValue);
            }
        };
    
        const handleIncrement = () => {
            onChange(value + 1);
        };
    
        const handleDecrement = () => {
            onChange(value - 1 >= 0 ? value - 1 : 0);
        };
    
        return (
            <div className='number-input' style={style}>
                <button className='number-input__btn' onClick={handleDecrement}>-</button>
                <input
                    type="text"
                    className='number-input__input'
                    value={value}
                    onChange={handleInputChange}
                />
                <button className='number-input__btn' onClick={handleIncrement}>+</button>
            </div>
        );
        
};

NumberInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object
};
export default NumberInput;