import React, { useEffect, useState } from 'react';
import TopPageHeader from '../components/TopPageHeader';
import Modal from '../components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

const SetUpCompanyPage = () => {
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [postData, setPostData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [pageno,PageNumber]=useState(1);
    const navigate = useNavigate();
   
    const handleEditInputChange = (property, value) => {
        setSelectedItem({
            ...selectedItem,
            [property]: value,
        });
    };

    const clearSelectedItem = () => {
        setSelectedItem(null);
    };

    const getcompany = (searchvalue,page) => {
        const formData = new FormData();
        let offset=(page-1)*20;
        const url = "https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=get-company";
        formData.append('offset',String(offset));
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body:formData,
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data } = json;
            if (success == 1) {
                setPostData(data);
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    };
    
    const AddNew = () =>{
        clearSelectedItem();
        setIsAddNewOpen(true);
    };

       
    const addnewcompany = () =>{
        const confirmed = window.confirm("Are you sure you want to save changes?");
        if (confirmed){
        const formData = new FormData();
        const url="https://proxy.cors.sh/https://adminpanel.mewahautoworks.com.my/api/mewahautowork_admin.php?mode=add-company";
        formData.append('name', selectedItem.name);
        formData.append('phone', selectedItem.phone);
        formData.append('email', selectedItem.email);
        formData.append('address', selectedItem.address);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('token'),
                'Access-Control-Allow-Credentials':true,
                'x-cors-api-key': 'temp_27e255c4dce40f26979b9873b6547c0c'
            },
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('API Failed');
                }
            }
            return response.json();
        })
        .then((json) => {
            const { success, data} = json;
            if (success==1){
                toast.success("Company Create Completed", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setIsAddNewOpen(false);
                            getcompany('',1);
            }else if(success==2){
                toast.error("Company Name Exist", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(error => {
            console.error('Server Connection Failed');
        });
    }
    else{
        toast.error("Company Create Canceled", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    const searchfunction =(value)=>{
        setSearchData(value);
        getcompany(value,1)
    }

    const previouspage =()=>{
        if (pageno!=1){
            setPageNumber(pageno-1);
        }
    }
    const setPageNumber =(page)=>{
        PageNumber(page);
        getcompany('',page)
    }
    useEffect(() => {
        getcompany('',1);

    }, []);
    

    return (
            <div className='main-content'>
                <TopPageHeader title='Company' />

                <section className='content-area'>
                    <header className='content-area__header'>
                        <button className='content-area__header--btn' onClick={() => AddNew()}>+ Add New Company</button>
                        {
                            isAddNewOpen &&
                            <Modal
                                title='Add New Company'
                                setIsOpen={setIsAddNewOpen}
                                actionBtn={<button className='form-action-btn' onClick={() => addnewcompany()}>Save</button>}
                            >
                                <div className='grid-3-form'>
                                    <div className='form-control'>
                                        <label className='form-control__label'>Company Name</label>
                                        <input className='form-control__input' type="text" placeholder='Company Name' onChange={(e) => handleEditInputChange('name', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Phone No</label>
                                        <input className='form-control__input' type="text" placeholder='Phone No' onChange={(e) => handleEditInputChange('phone', e.target.value)} />
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Email</label>
                                        <input className='form-control__input' type="text" placeholder='Email' onChange={(e) => handleEditInputChange('email', e.target.value)}/>
                                    </div>

                                    <div className='form-control'>
                                        <label className='form-control__label'>Address</label>
                                        <input className='form-control__input' type="text" placeholder='Address' onChange={(e) => handleEditInputChange('address', e.target.value)}/>
                                    </div>
                                </div>
                            </Modal>
                        }

                        <div>
                            <div className='content-area__header--search'>
                                <img className='search-icon' src='./../images/search-icon.png' alt='search icon' />
                                <input className='search-input' type="text" placeholder='Search' value={searchData} onChange={(e) =>{searchfunction(e.target.value)}}/>
                            </div>

                            <nav className='content-area__header--pagination'>
                                <button className='pagination-btn nav' onClick={previouspage}>&lt;</button>
                                <button className={`pagination-btn ${pageno === 1 ? 'active' : ''}`} onClick={() => setPageNumber(1)}>1</button>
                                <button className={`pagination-btn ${pageno === 2 ? 'active' : ''}`} onClick={() => setPageNumber(2)}>2</button>
                                <button className={`pagination-btn ${pageno === 3 ? 'active' : ''}`} onClick={() => setPageNumber(3)}>3</button>
                                <button className='pagination-btn'>...</button>
                                <button className={`pagination-btn ${pageno === 10 ? 'active' : ''}`} onClick={() => setPageNumber(10)}>10</button>
                                <button className='pagination-btn nav' onClick={() => setPageNumber(pageno+1)}>&gt;</button>
                            </nav>
                        </div>
                    </header>

                    <div className='content-box'>
                        <div className='data-table scroll-sm'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Company Name.</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {postData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(pageno-1)*20+index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
    )
}

export default SetUpCompanyPage;