import React from 'react';
import { NavLink } from 'react-router-dom';
import LeftNavMenu from './LeftNavMenu';

function LeftNav() {
    return (
        <div className='left-nav'>
            <div className='left-nav__logo'>
                <img className='left-nav__logo--img' src='./../images/logo.png' alt='logo' />
            </div>

            <nav className='left-nav__nav'>
                <NavLink to='/core/dashboard' className='left-nav__nav--btn'>
                    <img className='nav-btn-icon' src='./../images/dashboard-icon.png' alt='icon' />
                    <p className='nav-btn-text'>Dashboard</p>
                    <img className='arrow-icon' src='./../images/right-arrow-icon.png' alt='arrow-icon' />
                </NavLink>

                <LeftNavMenu title='Job' icon={<img className='nav-btn-icon' src='./../images/job-icon.png' alt='icon' />}>
                    <NavLink to='/core/job' className='sub-nav-btn'>Job</NavLink>
                    <NavLink to='/core/job-history' className='sub-nav-btn'>History</NavLink>
                </LeftNavMenu>

                <NavLink to='/core/tech-assistant' className='left-nav__nav--btn'>
                    <img className='nav-btn-icon' src='./../images/tech-assistant-icon.png' alt='icon' />
                    <p className='nav-btn-text'>Tech Assistant</p>
                    <img className='arrow-icon' src='./../images/right-arrow-icon.png' alt='arrow-icon' />
                </NavLink>

                <LeftNavMenu title='Setup' icon={<img className='nav-btn-icon' src='./../images/setup-icon.png' alt='icon' />}>
                <NavLink to='/core/setup-company' className='sub-nav-btn'>Company</NavLink>
                    <NavLink to='/core/setup-customer' className='sub-nav-btn'>Customer</NavLink>
                    <NavLink to='/core/setup-vehicle' className='sub-nav-btn'>Vehicle</NavLink>
                    <NavLink to='/core/setup-service' className='sub-nav-btn'>Service</NavLink>
                    <NavLink to='/core/setup-user' className='sub-nav-btn'>User</NavLink>
                    <NavLink to='/core/setup-foreman' className='sub-nav-btn'>Foreman</NavLink>
                </LeftNavMenu>

                <NavLink to='/core/inventory' className='left-nav__nav--btn'>
                    <img className='nav-btn-icon' src='./../images/inventory-icon.png' alt='icon' />
                    <p className='nav-btn-text'>Inventory</p>
                    <img className='arrow-icon' src='./../images/right-arrow-icon.png' alt='arrow-icon' />
                </NavLink>
            </nav>
        </div>
    )
}

export default LeftNav;